<template>
  <v-container fluid class="fill-height ma-0 pa-0" v-if="callLists">
    <v-row no-gutters class="fill-height">
      <v-col cols="12">
        <v-tabs v-model="tab" left background-color="panelheader">
          <v-tab class="text-h6 text-uppercase">{{ tLabel("Discharge") }}</v-tab>
          <v-tab class="text-h6 text-uppercase">{{ tLabel("Load") }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="fill-height scroller" style="height: calc(100vh - 254px)">
          <v-tab-item class="fill-height" :class="dischargeStatusColor(callLists.call.status) + ' lighten-5'">
            <VoyagePanel :voyage="voyageIn" />
          </v-tab-item>
          <v-tab-item class="fill-height" :class="loadStatusColor(callLists.call.status) + ' lighten-5'">
            <VoyagePanel :voyage="voyageOut" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VesselMixin from "../../mixins/vessel/VesselMixin";
import VoyagePanel from "../../components/vessel/VoyagePanel.vue";

export default {
  name: "CallListsPanel",
  data() {
    return {
      callLists: null,
      tab: null,
    };
  },
  props: {
    callId: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    this.callLists = await this.$api.vesselData.getCallListsById(this.callId);
    console.log("callLists", this.callLists, JSON.stringify(this.callLists));
  },
  components: { VoyagePanel },
  mixins: [VesselMixin],
  computed: {
    voyageIn() {
      if (!this.callLists) {
        return null;
      }
      return {
        code: this.callLists.call.voyageIn,
        direction: "ARRIVAL",
        callStatus: this.callLists.call.status,
        utiList: this.callLists.dischargingList,
        utiCountMap: this.callLists.dischargeCountMap,
      };
    },
    voyageOut() {
      if (!this.callLists) {
        return null;
      }
      return {
        code: this.callLists.call.voyageOut,
        direction: "DEPARTURE",
        callStatus: this.callLists.call.status,
        utiList: this.callLists.loadingList,
        utiCountMap: this.callLists.loadCountMap,
      };
    },
  },
};
</script>

<style scoped>
.scroller {
  overflow-y: auto;
  scrollbar-width: thin;
}

.scroller::-webkit-scrollbar {
  width: 6px;
}

.scroller::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroller::-webkit-scrollbar-thumb {
  background: #888;
}

.scroller::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
