<template>
  <div>
    <v-container fluid style="height: calc(100vh - 88px)" class="grey lighten-4" v-if="call">
      <v-row align="center" no-gutters>
        <v-col cols="12">
          <v-card rounded="0">
            <v-card-text>
              <v-container fluid class="my-0 py-0">
                <v-row align="center">
                  <v-col cols="1" class="d-flex align-center">
                    <v-icon size="35" @click="goToCalls">mdi-arrow-left</v-icon>
                    <v-icon color="grey lighten-1" class="ml-8" size="50">mdi-ferry</v-icon>
                  </v-col>
                  <v-col cols="2">
                    <div class="text-subtitle-1">{{ call.vessel.name }}</div>
                    <div class="text-subtitle-2 font-weight-light">{{ call.position }}</div>
                  </v-col>
                  <v-col cols="2">
                    <div class="text-subtitle-2">OPERATOR: {{ call.operator }}</div>
                    <div class="text-subtitle-2">AGENT: {{ call.agent }}</div>
                  </v-col>
                  <v-col cols="2">
                    <div class="text-subtitle-2">ETA: {{ call.eta | formatDateTimeFromDate }}</div>
                    <div class="text-subtitle-2">ATA: {{ call.ata | formatDateTimeFromDate }}</div>
                  </v-col>
                  <v-col cols="2">
                    <div class="text-subtitle-2">ETD: {{ call.etd | formatDateTimeFromDate }}</div>
                    <div class="text-subtitle-2">ATD: {{ call.atd | formatDateTimeFromDate }}</div>
                  </v-col>
                  <v-divider vertical class="my-2" />
                  <v-col cols="2">
                    <div class="text-subtitle-2 pl-4">BEGIN: {{ call.beginOperation | formatDateTimeFromDate }}</div>
                    <div class="text-subtitle-2 pl-4">END: {{ call.endOperation | formatDateTimeFromDate }}</div>
                  </v-col>
                  <v-col cols="1" class="text-right">
                    <v-icon color="primary" size="50" @click="goToManageCallEditing"> mdi-pencil-circle </v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row align="center" no-gutters style="height: calc(100vh - 192px)">
        <v-col cols="12" class="py-2 fill-height">
          <v-tabs vertical class="fill-height" background-color="header" grow>
            <v-tab>
              <v-icon>mdi-file-document-outline</v-icon>
            </v-tab>
            <v-tab>
              <v-icon>mdi-account-hard-hat-outline</v-icon>
            </v-tab>
            <v-tab>
              <v-icon>mdi-cog-outline</v-icon>
            </v-tab>
         
            <v-tab-item>
              <CallListsPanel :callId="callId" />
            </v-tab-item>
            <v-tab-item >
              OPERATIVE
            </v-tab-item>
            <v-tab-item >
              TOOLS
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FormatsMixins from "../../mixins/FormatsMixins";
import CallListsPanel from "../../components/vessel/CallListsPanel.vue";

export default {
  name: "CallDetails",
  data() {
    return {
      call: null,
      tab: 0
    };
  },
  props: {
    callId: {
      type: String,
      required: true,
    },
    attrs: {
      type: Object,
      default: null,
    },
  },
  mixins: [FormatsMixins],
  components: { CallListsPanel },
  async mounted() {
    this.call = await this.$api.vesselData.getCallById(this.callId);
    console.log("call", this.call, JSON.stringify(this.call));
  },
  methods: {
    goToCalls() {
      if (this.attrs) {
      }
      this.$router.push({
        name: "CallsDaily",
        params: {
          attrs: this.attrs,
        },
      });
    },
    goToManageCallEditing() {
      // Redirecting to the ManageCall page in editing mode
      console.log("Navigating to EditCall with callId:", this.callId);
      this.$router.push({
        name: "EditCall",
        query: {
          callId: this.callId,
        },
      });
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
